import { CartClient } from '@/lib/cart-client'
import { getCurrentRegion } from '@/lib/region-helper'

export const getModalData = async (modalId) => {
  let region = getCurrentRegion()
  let locale = 'en-US'

  if (region?.storeSource == 'EU') {
    if (region?.localePrefix == 'en') {
      locale = 'en-GB'
    } else {
      locale = region?.localePrefix
    }
  }

  try {
    const { data } = await CartClient.get(`/content/modal`, {
      params: {
        modalId,
        locale,
      },
    })

    return data
  } catch (error) {
    console.log('getModalData error:', error)
  }
}

export const getSliderData = async (modalId) => {
  let region = getCurrentRegion()
  let locale = 'en-US'

  if (region?.storeSource == 'EU') {
    if (region?.localePrefix == 'en') {
      locale = 'en-GB'
    } else {
      locale = region?.localePrefix
    }
  }

  try {
    const { data } = await CartClient.post(`/content/slides`, {
      sliderId: modalId,
      locale: locale,
    })

    return data
  } catch (error) {
    console.log('getSliderData error:', error)
  }
}
