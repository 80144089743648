import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { addForwardSlash, getLinkType } from '@/lib/helpers'
import Icon from '@/components/icon/icon'
import { ctaClickGTM } from '@/lib/gtm'
import { useRouter } from 'next/router'
import { getModalData } from '@/lib/modal'

const Modal = dynamic(() => import('@/components/common/modal'))

const CustomButton = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  let size = props?.size ? `custom-button--${props.size}` : 'custom-button--lg'
  let variant = props?.variant ? `custom-button--${props.variant}` : 'custom-button--primary'
  let showLeftIcon = props?.variant ? (props?.variant?.includes('icon-left') ? true : false) : false
  let showRightIcon = props?.variant
    ? props?.variant?.includes('icon-right')
      ? true
      : false
    : false

  let additionalClasses = [size, variant]
  const linkType = getLinkType(props?.url)
  const router = useRouter()

  //GTM
  const handleCtaClick = (text, url, e) => {
    ctaClickGTM(text, url)

    if (linkType == 'modal') {
      e.preventDefault()
      setShowModal(true)
    }
  }

  useEffect(() => {
    const getModal = async () => {
      let data = await getModalData(props?.url?.replace('#',''))

      if (data) {
        setModalData(data)
      }
    }

    if (linkType == 'modal' && !modalData) {
      getModal()
    }
  },[router.asPath])

  if (props?.type == 'link' && props?.url && props?.label) {
    return (
      <>
        <Link
          onClick={(e) => {
            handleCtaClick(props?.label, props?.url, e)
          }}
          href={addForwardSlash(props?.url)}
          className={`custom-button inline-flex items-center rounded-full ${additionalClasses.join(
            ' '
          )}`}
        >
          {showLeftIcon && (
            <Icon
              className={`custom-button__icon custom-button__icon--left cursor-pointer flex-shrink-0`}
              viewBox={`0 0 12 12`}
              size={12}
              fill={
                variant.includes('secondary') || variant.includes('tertiary') ? '#000000' : '#E36F22'
              }
              icon="arrow_left"
            />
          )}
          <span>
            {props?.label}
          </span>
          {showRightIcon && (
            <Icon
              className={`custom-button__icon custom-button__icon--right cursor-pointer flex-shrink-0`}
              viewBox={`0 0 12 12`}
              size={12}
              fill={
                variant.includes('secondary') || variant.includes('tertiary') ? '#000000' : '#E36F22'
              }
              icon="arrow_right"
            />
          )}
        </Link>
        {
          showModal && modalData && <Modal
            isOpen={showModal}
            setIsOpen={setShowModal}
            {...modalData} />
        }
      </>
    )
  }

  return <button>{/* TODO WHEN NEEDED */}</button>
}

export default CustomButton
